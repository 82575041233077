import React from 'react'
import { config, useSpring } from 'react-spring'
import Layout from '../components/layout'
import { AnimatedBox } from '../elements'
import SEO from '../components/SEO'
import styled from 'styled-components'
import { Button } from '../elements'
import PostItem from "../components/postItem"
import { graphql, Link } from 'gatsby'
import signature from '../signature.png'

const PostFeed = styled(AnimatedBox)` 
position: relative;
display: flex;
flex-wrap: wrap;
`

const PBox = styled(AnimatedBox)`
max-width: 1400px;
margin: 0 auto;
padding-bottom: 3rem;
`

const PButton = styled(Button) <{ color: string }>`
background: ${props => (props.color === 'white' ? 'black' : props.color)};
`

const Success = (props) => {
    const { data: { allNotion } } = props
    const pageAnimation = useSpring({
        config: config.slow,
        from: { opacity: 0 },
        to: { opacity: 1 },
    })

    return (
        <Layout color='black'>
            <SEO title="Success 🎉 | Conrad" desc="You're on the list! 🔥" />
            <PBox style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 8, 13]} >
                <h1>You're on the list! 🔥</h1>
                <p>
                Check your inbox for an email ✉ from us! <br /><em style={{ fontSize: '14px' }}>(please double-check your junk and whitelist <strong>@conradlin.com</strong> and <strong>@co-x3.com</strong>)</em>.
                    <br />
                    <br />
                    Hi, <Link to="/about">Conrad</Link> here 👋, the founder of <Link to="/thex3family">The Co-x3 Family Foundation</Link>.
                    <br />
                    <br />
                    Building a community of co-creation and collaboration has been my absolute passion and joy, and I cannot <markl>thank you enough</markl>. It's because of <markl>you</markl>, that I decide to wake up everyday and create more value for the community. It's because of <markl>you</markl> that I keep innovating new ways for us all to <strong>live a more meaningful, fulfilling life</strong>.
                    <br />
                    <br />
                    Let's make a pact. Every time I deliver content that makes you go <strong>WOW</strong> (😮 - <em>just like this emoji</em>), I want you to <markl>let me know</markl>, so I can do that again. This is a community built for you, and I intend for it to stay that way. So <markl>it's only with your input</markl>, that <strong>you and me become we.</strong> It's that simple.
                    <br />
                    <br />
                    That's enough from me, there's no time to waste - <strong>let's dive into your next quest!</strong> 👇
                </p>

                <img
                    alt="Conrad Lin"
                    style={{ height: '70px', padding: '10px 0px' }}
                    src={signature}
                />
                <PostFeed style={pageAnimation} pt = {[6]}>
                    {
                        allNotion.edges.map(e => <PostItem data={e} />)
                    }
                </PostFeed>

                <PBox style={{ textAlign: 'center' }} py={1} px={[6, 6, 8, 10]}>
                    <h2>Have Any Suggestions?</h2>
                    <a href="mailto:lets.talk@conradlin.com" target="_blank">
                        <PButton color="white" py={4} px={8}>
                            Let Me Know
                        </PButton>
                    </a>
                </PBox>
            </PBox>
        </Layout>
    )
}

export default Success

export const query = graphql`
  query {
    allNotion(
        filter: {properties: {status: {value: {name: {eq: "published"}}}}}
        limit: 2
        sort: {fields: childMdx___frontmatter___publish_date___start, order: DESC}
      ) {
        edges {
          node {
            localImage {
              childImageSharp {
                fluid(quality: 95, maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            childMdx {
              frontmatter {
                title
                tags {
                  name
                }
                content_type {
                  name
                }
                desc
                status {
                  name
                }
                url
                read_time
                publish_date {
                  start(fromNow: false, formatString: "YYYY-MMM-DD")
                }
              }
              timeToRead
            }
          }
        }
    }
  }
`
